// =================================================
// PAGE LESS
// =================================================
// Page control
// -------------------------------------------------
.page {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;

  h5 {
    font-size: 20px;
    color: $global-text-color;
    font-weight: 600;
    text-transform: uppercase;

    small {
      font-size: $global-font-size;
      color: #000;
      font-weight: lighter;
      text-transform: none;
    }
  }

  span.sub-title {
    position: relative;
    font-size: 14px;
    color: $global-text-color;

    &:before {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: -5px;
      background-color: $global-text-color;
    }
  }

  // section
  &-section {
    // nav
    &-nav {
      margin-top: 12px;
      @media (max-width: $screen-medium-max) {
        margin-bottom: 26px;
      }

      // head
      &--head {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      li {
        a {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #000;
          font-size: 14px;

          small {
            font-size: 14px;
            position: relative;
            width: 22px;
          }

          &:hover {
            opacity: .7;
          }
        }
      }
    }

    // content
    &-content {
      h6 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 16px;
      }
      address {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }
    }
  }
}