// =================================================
// UPLOADER LESS
// =================================================
// Uploader fixed
// -------------------------------------------------
@mixin uploader-fixed() {

  .modal {
    background-color: rgba($dark, .75);
    // lg
    &-lg {
      @media (min-width: $screen-large) {
        max-width: 1035px;
      }
    }

    // content
    &-content {
      border-radius: 0;
      border: 0 none;
      background-color: #F5F5F5;
    }

    // header
    &-header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      padding: 42px 0;

      h5 {
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    // body
    &-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 373px;

      @media (min-width: $screen-medium) {
        padding: 0 108px;
      }

      // info label
      .info-label {
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin-top: 12px;

        span {
          font-size: 16px;
          font-weight: normal;
        }
      }

      // container
      .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    // footer
    &-footer {
      display: flex;
      justify-content: space-between;
      border: 0;
      margin-top: 28px;

      @media (min-width: $screen-medium) {
        padding: 28px 108px;
      }

      * {
        margin: 0;
      }

      @media (max-width: $screen-medium-max) {
        .btn {
          display: block;
          width: 100%;
          text-align: center;
          &:not(:last-child) {
            margin-bottom: 3px;
          }
        }
      }
    }
  }

  // wrapper
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    background-color: $white;
    border: 1px solid #404040;
    min-height: 100%;
    position: relative;
    overflow-y: scroll;
  }

  // add
  &-add {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 42px 0;
    width: 100%;
    min-height: 100%;

    i {
      font-size: 62px;
      margin-bottom: 26px;
    }

    span {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .btn-select {
      position: relative;
      border: 1px solid $dark;
      margin-top: 16px;
      padding: 4px 32px;
      text-transform: none;
      font-size: 18px;
      cursor: pointer;
    }
  }

  // preview
  &-previews {
    width: 100%;
    height: 100%;
  }

  // items
  &-items {
    position: relative;
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid $global-border-color;
    }

    // figure
    &-figure {
      position: relative;
      width: 100%;

      img {
        width: 100%;
        object-fit: fill;
      }
    }

    // name
    &-name {
      color: #000000;
      font-weight: bold;
      font-size: $global-font-size;
    }

    // progress-bar
    .progress {
      background-color: $faded;

      &-bar {
        background-color: $dark;
      }
    }
  }

  .add-item-btn {
    position: relative;
    display: none;
  }
}

// Uploader control
// -------------------------------------------------
.uploader {
  @include uploader-fixed;
}