.mb-55 {
  margin-bottom: 55px;
}

.mb-40 {
  margin-bottom: 40px;
}

.product-overview-four-col {
  // Ui improvement
  padding-bottom: 50px;

  .section-text-container {
    // margin-bottom: 25px;

    .section-title {
      font-size: 28px;
      line-height: 37px;
      margin-bottom: 25px;
      color: $primary;
      font-weight: bold;
    }

    .section-text {
      font-family: "Roboto-Light", sans-serif;
      font-size: 20px;
      line-height: 36px;
    }
  }

  .product-wrapper {
    .product-img {
      margin-bottom: 5px;
      // ui improvement
      height: auto;
      max-width: 100%;
      display: flex;
      align-items: center;
      // ui improvement
      img {
        border: 2px solid transparent;
        transition: all 1s ease;

        &:hover {
          border: 2px solid #92c0f4;
        }
      }
    }

    .product-desc {
      p {
        font-size: 15px;
        line-height: 20px;
        font-family: "Roboto-Regular", sans-serif;
        color: #000000;
        margin-bottom: 5px;
      }
      span {
        color: $primary;
        display: block;
        font-family: "Roboto-Bold", sans-serif;
        font-size: 17px;
        line-height: 22px;
      }
    }
  }
}

.feature-products-row {
  padding: 85px 0;
  background-color: #ebebeb;

  .product-wrapper {
    @media (max-width: $screen-small) {
      margin-bottom: 85px;
    }

    .product-img {
      margin-bottom: 20px;

      img {
        border: 2px solid transparent;
        transition: all 1s ease;

        &:hover {
          border: 2px solid #92c0f4;
        }
      }
    }

    .product-desc {
      margin-bottom: 25px;

      .product-title {
        font-size: 18px;
        line-height: 32px;
        color: #000000;
        margin-bottom: 10px;
      }

      p {
        font-size: 15px;
        line-height: 20px;
        font-family: "Roboto-Regular", sans-serif;
        color: #000000;
        margin-bottom: 0;
      }
    }
  }
}

.product-overview-three-col {
  padding-top: 55px;
  padding-bottom: 185px;

  .section-text-container {
    // margin-bottom: 55px;

    .section-title {
      font-size: 28px;
      line-height: 37px;
      margin-bottom: 15px;
      color: #313131;
    }

    .section-text {
      font-family: "Roboto-Light", sans-serif;
      font-size: 20px;
      line-height: 36px;
    }
  }

  .product-wrapper {
    .product-img {
      margin-bottom: 5px;

      img {
        border: 2px solid transparent;
        transition: all 1s ease;

        &:hover {
          border: 2px solid #92c0f4;
        }
      }
    }

    .product-desc {
      p {
        font-size: 15px;
        line-height: 20px;
        font-family: "Roboto-Regular", sans-serif;
        color: #000000;
        margin-bottom: 5px;
      }
      span {
        color: $primary;
        display: block;
        font-family: "Roboto-Bold", sans-serif;
        font-size: 17px;
        line-height: 22px;
      }
    }
  }
}
