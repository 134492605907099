// =================================================
// ADDRESS LESS
// =================================================
// Address fixed
// -------------------------------------------------
@mixin address-fixed() {
  position: relative;
  width: 100%;

  // row-eq
  .row-eq {
    @media (min-width: $screen-medium) {
      margin-right: -71px;
      margin-left: -71px;
      [class*="col-"] {
        padding-right: 71px;
        padding-left: 71px;
      }
    }
  }

  // page
  .page-title {
    @media (min-width: $screen-medium) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      span {
        display: none;
      }
    }
    @media (max-width: $screen-medium-max) {
      small {
        display: none;
      }

      span {
        &.default {
          font-weight: 400;

          &:before {
            display: none;
          }
        }
      }
    }
  }
}

// Shipping items fixed
// -------------------------------------------------
@mixin shipping-items-fixed() {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f5f5f5;
  padding: 28px;

  @media (min-width: $screen-medium) {
    margin-top: 50px;
    &.new {
      margin-top: 5px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

// Shipping content fixed
// -------------------------------------------------
@mixin shipping-content-fixed() {
  flex: 1 0 calc(100% - 100px);
}

// Shipping icon fixed
// -------------------------------------------------
@mixin shipping-icon-fixed() {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 100px;
  width: 82px;
  height: 82px;
  max-width: 82px;
  max-height: 82px;
  border: 2px solid #000000;
  border-radius: 100px;
  background-color: transparent;

  @media (max-width: $screen-medium-max) {
    display: none;
  }

  i {
    font-size: 46px;
  }
}

// Invoice Search
// -------------------------------------------------
@mixin invoice-search-fixed() {
  position: relative;
  background-color: #F8F8F8;
  padding: 12px;
  margin-bottom: 12px;

  @media (min-width: $screen-medium) {
    margin-top: 42px;
  }
}

// Invoice search content
// -------------------------------------------------
@mixin invoice-search-content-fixed() {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: #ffffff;
  border-bottom: 2px solid #000000;
  padding: 0 8px;

  .form-control {
    background-color: transparent;
    height: 43px;
    border: 0 none;
  }
  .icon {
    position: relative;
    padding-left: 8px;
  }
}

// Invoice items
// -------------------------------------------------
@mixin invoice-items-fixed() {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 12px;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

// Invoice items
// -------------------------------------------------
@mixin invoice-btn-set-fixed() {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  position: relative;
  justify-content: space-between;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    top: 0;
    padding: 0;
    margin: 0;

    &-light {
      background-color: transparent;

      i {
        font-size: 20px;
      }
    }
  }
}

// Invoice content
// -------------------------------------------------
@mixin invoice-content-fixed() {
  display: flex;
  position: relative;
  width: 90%;

  // checkbox
  .radio {
    label {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .circle {
        margin-top: 4px;
        margin-right: 16px;
      }
    }
  }
}

// Invoice footer
// -------------------------------------------------
@mixin invoice-footer-fixed() {
  text-align: right;
  @media (max-width: $screen-medium-max) {
    margin-top: 12px;
    .btn {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}


// Btn next
// -------------------------------------------------
.btn-next {
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: $screen-medium-max) {
    position: relative;
    width: 100%;
    margin-top: 10px;
    .btn {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}

// Address control
// -------------------------------------------------
.address {
  @include address-fixed;

  // shipping
  .shipping {
    // items
    &-items {
      @include shipping-items-fixed;
    }

    // content
    &-content {
      @include shipping-content-fixed;
    }

    // icon
    &-icon {
      @include shipping-icon-fixed;
    }
  }

  // invoice
  .invoice {
    // search
    &-search {
      @include invoice-search-fixed;
      // content
      &-content {
        @include invoice-search-content-fixed;
      }
    }

    // content
    &-content {
      @include invoice-content-fixed;
    }

    // items
    &-items {
      @include invoice-items-fixed;
    }

    // btn set
    &-btn-set {
      @include invoice-btn-set-fixed;
    }

    // footer
    &-footer {
      @include invoice-footer-fixed;
    }
  }
}