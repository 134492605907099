// =================================================
// METHODS LESS
// =================================================
// Methods control
// -------------------------------------------------
.methods {
  // content
  &-content {
    display: flex;
    flex-direction: row;

    span {
      font-weight: bold;
      margin-right: 8px;
    }
  }
  // items
  &-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 12px;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    .radio {
      .circle {
        min-width: 12px;
        max-width: 14px;
        margin-left: 20px;
        margin-right: 32px;
      }

      .title {
        font-size: 18px;
        display: block;
        width: 100%;
        font-weight: 600;
        margin-bottom: 4px;
        text-transform: uppercase;
      }

      p {
        font-size: 12px;
        font-weight: normal;
      }
    }

    .img-set {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: 24px;

      img {
        width: 60px;
        height: 42px;

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }
}