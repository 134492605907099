// =================================================
// Footer LESS
// =================================================
// Footer Fixed
// -------------------------------------------------
@mixin footer-fixed() {
  position: relative;
  width: 100%;
  background-color: #EFEFEF;
  padding: 24px 0;
  box-shadow: 0 -11px 12px -12px rgba(0, 0, 0, .3);

  // logo
  &-logo {
    margin-top: 16px;
    margin-bottom: 26px;

    span {
      font-size: 26px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  // address
  &-address {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  // nav
  &-nav {
    @media (max-width: $screen-medium-max) {
      margin-bottom: 3rem;
    }
    // title
    &-title {
      font-size: 20px;
      font-weight: bold;
      color: #999999;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    // global links
    li {
      position: relative;

      &:before {
        content: '›';
        font-size: 14px;
        position: absolute;
        left: -5px;
        top: -2px;
      }

      a {
        font-size: $global-font-size;
        color: #000000;
        font-weight: normal;
        padding-left: 3px;
      }
    }

    .social-nav {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 100px;
        transition: all .2s ease-in-out;

        &:not(:last-child) {
          margin-right: 6px;
        }

        // hover
        &:hover {
          opacity: .7;
        }

        // icons
        i {
          font-size: 20px;
          color: #ffffff;
        }

        &.facebook {
          background-color: #004EFF;
        }

        &.twitter {
          background-color: #0093FF;
        }

        &.youtube {
          background-color: #FF0000;
        }

        &.instagram {
          background-color: #313131;
        }
      }
    }
  }

  // img icon set
  &-img-set {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    min-height: 130px;
  }
}


// Footer Control
// -------------------------------------------------
.footer {
  @include footer-fixed;
}