// =================================================
// FINISH LESS
// =================================================
// Finish fixed
// -------------------------------------------------
@mixin finish-fixed() {
  position: relative;
  margin-top: 4rem;

  // body
  &-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: $faded;
    padding: 24px 24px;
  }

  // title
  &-title {
    text-align: center;
    position: relative;
    margin-top: 26px;

    h5 {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  // content
  &-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: 3.6rem;

    // icon
    &-icon {
      position: relative;
      margin-bottom: 24px;

      i {
        font-size: 66px;
      }
    }

    // title
    &-title {
      h5 {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    // detail
    &-detail {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      span {
        b {
          margin-left: 3px;
        }
      }

      // message
      .message {
        margin-top: 16px;

        span {
          font-weight: bold;
        }
      }
    }
  }

  // footer
  &-footer {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
  }

  // newsletter
  &-newsletter {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    background-color: $faded;
    padding: 16px;

    // title
    &-title {
      h5 {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }

      span {
        font-size: 12px;
      }
    }

    .form-group {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-top: 16px;
      margin-bottom: 0;

      // label
      .form-label {
        font-size: 12px;
        text-transform: none;
      }

      // input set
      .input-set {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .form-control {
          width: 100%;
          height: 47px;
          background-color: #ffffff;
          flex: 1 0 calc(100% - 60px);
        }

        .btn {
          width: 60px;
          text-align: center;
          padding: 12px 12px;
        }
      }
    }
  }

  // banner
  &-banner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    background-color: $faded;
    padding: 16px;
    min-height: 172px;

    @media (max-width: $screen-medium-max) {
      margin-top: 16px;
    }

    // content
    &-content {
      position: relative;
      padding-left: 12px;

      h5 {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
      }
      p {
        font-size: 12px;
      }
    }
  }
}

// Finish control
// -------------------------------------------------
.finish {
  @include finish-fixed;
}