// =================================================
// LOGIN LESS
// =================================================
// Login fixed
// -------------------------------------------------
@mixin login-fixed() {
  position: relative;
  width: 100%;
}

// Login content fixed
// -------------------------------------------------
@mixin login-content-fixed() {
  position: relative;

  @media (min-width: $screen-medium) {
    padding: 0 76px;
  }

  // muted
  &--muted {
    display: flex;
    height: 100%;
    background-color: #CBCBCB;

    // form block
    .form-block {
      background-color: #F5F5F5;
    }

    // form control
    .form-control {
      background-color: $white;
    }
  }

  // white
  &--white {
    display: flex;
    height: 100%;
    background-color: $white;
    border: 1px solid $dark;

    @media (max-width: $screen-medium-max) {
      margin-top: 16px;
    }

    .form-control {
      background-color: #F5F5F5;
    }

    .btn {
      @media (max-width: $screen-medium-max) {
        width: 100%;
      }
      @media (min-width: $screen-medium) {
        width: 50%;
      }
    }
  }
}

// Login form block fixed
// -------------------------------------------------
@mixin login-form-block-fixed() {
  display: inline-table;
  position: relative;
  width: 100%;
  padding: 22px;

  // title
  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 16px;

    i {
      font-size: 24px;
    }

    span {
      font-size: 22px;
      font-weight: 600;
    }
  }

  // label
  .form-label {
    text-transform: none;
  }

  // btn
  .btn {
    text-align: center;
  }
}

// Login control
// -------------------------------------------------
.login {
  @include login-fixed;

  // content
  &-content {
    @include login-content-fixed;
  }

  // form block
  .form-block {
    @include login-form-block-fixed;
  }
}