// =================================================
// Checkbox & Radio LESS
// =================================================
// Checkbox control
// -------------------------------------------------
.checkbox {
  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    background-color: transparent;
    border-radius: 100px;
    cursor: pointer;

    .circle {
      width: 12px;
      height: 12px;
      border-radius: 100px;
      border: 1px solid #000000;
      position: relative;
      margin-right: 6px;
      transition: all .2s ease;
      margin-top: -2px;
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked + label {
    .circle {
      border-width: 5px;
    }
  }
}

.radio {
  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    background-color: transparent;
    border-radius: 100px;
    cursor: pointer;

    .circle {
      width: 12px;
      height: 12px;
      border-radius: 100px;
      border: 1px solid #000000;
      position: relative;
      margin-right: 6px;
      transition: all .2s ease;
      margin-top: -2px;
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label {
    .circle {
      border-width: 5px;
    }
  }
}