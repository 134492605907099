.single-product-detail {
  .single-product-image-gallery {
    @media (max-width: $screen-small) {
      margin-bottom: 30px;
    }

    .product-images-thumbnail {
      .nav-item {
        .nav-link {
          border-radius: 0;
          padding: 10px 6px;
        }
        .nav-link.active {
          background-color: transparent;
        }
      }
    }
  }

  .single-product-description {
    .single-product-title {
      font-size: 2rem;
      line-height: 2.5rem;
      color: $dark;
      margin-bottom: 2.43rem;
    }
    .single-product-content-tab {
      margin-bottom: 3.125rem;
      .nav-tabs {
        margin-bottom: 1.25rem;

        .nav-link {
          font-family: $roboto-bold;
          font-size: 18px;
          line-height: 24px;
          color: #cbcbcb;
          text-transform: uppercase;
          padding-left: 0;
          padding-top: 0;
          padding-bottom: 0;
          border: none;
          border-radius: 0;
        }
        .nav-link.active {
          color: #000000;
        }
      }

      .product-content-tab {
        font-family: $global-font-family;
        color: #313131;
        font-size: 12px;
        line-height: 24px;

        ul li {
          list-style: disc;
        }
      }
    }
  }

  .single-product-meta {
    margin-bottom: 1.563rem;

    .form-group {
      background-color: #f8f8f8;
      padding: 20px 40px 40px;
      margin-bottom: 0.625rem;

      label {
        font-family: $roboto-bold;
        font-size: 1.125rem;
        line-height: 2rem;
        margin-bottom: 1.25rem;
      }

      .form-control {
        font-family: $global-font-family;
        font-size: 1rem;
        line-height: 1.312rem;
        background-color: $white;
        border-bottom: 2px solid $dark;
      }

      input {
        padding: 8px 20px;
      }

      select.form-control[multiple] {
        min-height: 400px;
        padding: 0;
        option {
          padding: 15px 20px;
        }
      }

      .bootstrap-select {
        background-color: transparent;
        border-bottom: 2px solid $dark;

        .btn {
          background: transparent;
          padding: 8px 20px;

          &:focus {
            background-color: transparent;
            outline: 0 !important;
            box-shadow: none;
          }
        }

        .dropdown-menu {
          border-radius: 0;
        }
      }
    }
  }

  .single-product-quantity {
    margin-bottom: 1.563rem;

    .product-quantity-heading {
      h6 {
        font-size: 1.125rem;
        line-height: 2rem;
        margin-bottom: 0.937rem;
      }
    }

    .product-quantity-table {
      .table {
        .thead-light {
          th {
            background-color: #d3d3d3;
            border-color: #d3d3d3;
            text-transform: uppercase;
            font-family: $roboto-bold;
            color: $dark;
          }
        }
        td {
          font-family: $roboto-light;
          font-size: 13px;
          line-height: 30px;
          color: $dark;

          .tax-text {
            font-size: 8px;
            line-height: 0;
            display: block;
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background-color: #d3d3d3;
            cursor: pointer;
            font-weight: bold;
          }
        }
      }

      .table td {
        border-top: 2px solid #ffffff;
      }

      .view-more {
        a {
          color: #000000;
          font-size: 14px;
          line-height: 18px;
          border-bottom: 1px solid #000000;
        }
      }
    }
  }

  .cart-summary-wrapper {
    h6 {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      color: $dark;
      text-transform: uppercase;
    }

    .cart-product-info {
      background-color: #f8f8f8;
      margin-bottom: 1rem;

      h6 {
        margin-bottom: 1.75rem;
      }

      ul {
        font-family: $global-font-family;
        font-size: 14px;

        li {
          padding-bottom: 1.25rem;

          span {
            font-family: $roboto-bold;
          }

          &:last-child {
            padding-bottom: 12px;
          }
        }
      }
    }

    .shipping-and-delivery-info {
      background-color: #f8f8f8;

      h6 {
        margin-bottom: 1.22rem;
      }
      .delivery-data {
        font-size: 1.25rem;
        line-height: 1.25rem;
        font-family: $global-font-family;
        color: $dark;
      }
      .delivery-info {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $dark;
      }
    }
  }
}

.cart-total-info {
  background-color: #f8f8f8;
  margin-bottom: 0.88rem;

  h6 {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
  }

  ul {
    font-family: $global-font-family;
    margin-bottom: 0.937rem;

    li {
      padding-bottom: 5px;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .cart-total-box {
    background-color: #d9d9d9;
    font-weight: bold;
    font-size: 1.25rem;
  }

  .btn-upload-file {
    background-color: $dark;
    color: $white;
    font-family: $global-font-family;
    font-size: 1.125rem;
    line-height: 0.937rem;
  }

  a {
    color: $dark;
    font-family: $global-font-family;
    font-size: 18px;
    line-height: 15px;
  }

  .btn-upload-file {
    background-color: $dark;
  }

  .btn-add-to-cart {
    background-color: $primary;
    color: $text-primary;
  }
}

// Custom style
.use-mcp-style {
  .attribute-selector {
    .form-group label {
      top: 6px;
    }
    .form-group.form-group-active label {
      top: 0px;
    }
    .react-select-wrapper {
      padding: 40px 20px;
    }
  }
}
.webshop-product-desc-text {
  li {
    list-style: decimal;
  }
}
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  white-space: pre-wrap;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 25%;
  margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
