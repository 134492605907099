.breadcrumb {
  margin: 90px 0;
  padding: 0;

  .breadcrumb-item {
    font-family: "Helvetica Neue";
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: '\f054';
    font-family: 'Font Awesome 5 Pro' !important;
    font-weight: 700;
    font-size: 8px;
    line-height: 16px;
    vertical-align: middle;
  }

  .breadcrumb-item.active {
    color: #B5B5B5;
  }
}