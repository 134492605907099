// =================================================
// FORM LESS
// =================================================
// Form control
// -------------------------------------------------
.form {
  // control
  &-control {
    color: $global-text-color;
    border: 0 none;
    border-radius: 0;
    background-color: $faded;

    &:focus {
      color: $global-text-color;
      background-color: $faded;
      box-shadow: none;

      &::placeholder {
        opacity: 0;
      }
    }

    &::placeholder {
      color: $global-placeholder;
    }
  }

  // label
  &-label {
    font-size: $global-font-size;
    font-weight: 600;
    text-transform: uppercase;
    sup {
      color: $danger;
    }
  }
}