// =================================================
// CAROUSEL LESS
// =================================================
// Carousel control
// -------------------------------------------------
.carousel {
  width: 100%;
  overflow: hidden;

  &:hover {
    .carousel-control-next i,
    .carousel-control-prev i {
      opacity: 1;
    }
  }

  // indicators
  &-indicators {
    li {
      width: 6px;
      height: 6px;
      background-color: $white;
      border-radius: 50%;
      opacity: 1;
      margin-right: 8px;
      margin-left: 6px;

      &.active {
        background-color: $dark;
      }
    }
  }

  // control
  &-control {
    &-next,
    &-prev {
      i {
        color: $global-text-color;
        font-size: 28px;
        opacity: 0;
        transition: opacity 0.3s ease-in;
      }
    }
  }

  &-item {
    height: inherit;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.slider-index {
    @media (max-width: $screen-large-max) {
      height: 100%;
    }

    @media (min-width: $screen-large) {
      height: calc(90vh - 80px);
    }
    // caption
    .carousel-caption {
      left: 17.5%;
      top: 15%;
      bottom: auto;
      width: auto;
      color: #000000;
      z-index: 99;
      text-align: left;

      .content {
        max-width: 480px;

        h1 {
          font-size: 32px;
          text-transform: uppercase;
          font-weight: 800;
        }

        p {
          margin: 0;
          padding: 0;
          font-weight: 500;
          color: $global-text-color;
        }

        .btn-light {
          background-color: $white;
          color: $global-text-color;
          padding: 5px 46px;
        }
      }
    }

    // inner
    .carousel-inner {
      @media (max-width: $screen-large-max) {
        height: 50vh;
      }
      @media (min-width: $screen-large) {
        height: 70vh;
      }

      img {
        display: block;
        object-fit: contain;
      }
    }
  }

  &.product-slider {
    .card {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      width: 100% !important;

      .card-img-top {
        border-radius: 0;
      }

      &:hover {
        .icon-arrow {
          opacity: 1;
        }
      }

      &-title {
        font-size: 18px;
        font-weight: 600;
        color: $primary;
        text-transform: uppercase;
      }

      &-body {
        padding: 0;
        margin-top: 16px;
        background-color: $white;
      }

      .icon-arrow {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 27%;
        right: 23px;
        width: 36px;
        height: 36px;
        background-color: $white;
        border: 1px solid $dark;
        border-radius: 100px;
        font-size: 18px;
        opacity: 0;
        transition: opacity 0.3s ease-in;
      }
    }

    .carousel-indicators {
      position: relative;
      margin-top: 22px;

      li {
        background-color: #cbcbcb;

        &.active {
          background-color: $dark;
        }
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      height: 80px;
      top: 27%;

      i {
        opacity: 1;
        color: #999999;
      }
    }

    .row {
      @media (max-width: $screen-medium-max) {
        flex-wrap: nowrap;
      }
    }
  }
}
