// =================================================
// CONTAINER LESS
// =================================================
// Container control
// -------------------------------------------------
.container {
  @media (min-width: $screen-large) {
    max-width: 1280px;
  }
  // Fluid
  &-fluid {
    @media (min-width: $screen-medium ) {
      padding-right: 62px;
      padding-left: 62px;
    }
  }
}
