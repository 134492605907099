// =================================================
// CART LESS
// =================================================
// Cart fixed
// -------------------------------------------------
@mixin cart-fixed() {
  position: relative;
}

// Cart title fixed
// -------------------------------------------------
@mixin cart-title-fixed() {
  position: relative;

  @media (max-width: $screen-medium-max) {
    margin-top: 22px;
  }

  h5 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 18px;
  }
  span {
    position: relative;
    font-size: 14px;
    color: $global-text-color;

    &:before {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: -5px;
      background-color: $dark;
    }
  }
}

// Cart header fixed
// -------------------------------------------------
@mixin cart-header-fixed() {
  position: relative;
  width: 100%;
  margin-top: 46px;
  border-bottom: 1px solid $dark;
}

// Cart body fixed
// -------------------------------------------------
@mixin cart-body-fixed() {
  position: relative;
  margin-top: 26px;
  border-bottom: 2px solid $dark;
}

// Cart body fixed
// -------------------------------------------------
@mixin cart-items-fixed() {
  position: relative;
  padding-bottom: 16px;
  &:not(:last-child) {
    border-bottom: 1px solid $dark;
    margin-bottom: 16px;
    padding-bottom: 16px;
  }
  &-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

// Cart article fixed
// -------------------------------------------------
@mixin cart-article-fixed() {
  &-img {
    position: relative;
    margin-right: 28px;
    @media (max-width: $screen-medium-max) {
      display: none;
    }
  }

  // detail
  &-detail {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  // name
  &-name {
    @media (min-width: $screen-medium) {
      max-width: 95%;
    }

    span {
      font-size: 18px;
      font-weight: 600;
    }
  }

  // btn-set
  &-btn-set {
    margin-top: 12px;

    .btn-muted {
      font-size: 14px;
      height: 35px;
      width: 100px;
      text-align: center;
      background-color: #cbcbcb;
      border-color: #cbcbcb;
      color: $white;
      padding: 4px 26px;

      &:hover {
        background-color: darken(#cbcbcb, 5%);
      }

      &:not(:last-child) {
        margin-right: 28px;
      }
    }
  }

  // quantity & total
  &-quantity,
  &-total {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    color: $dark;
  }
  &-total {
    font-weight: 600;
  }

  //reference
  &-reference {
    color: $dark;
    background-color: #f5f5f5;
    border: 1px solid #707070;

    @media (max-width: $screen-medium-max) {
      margin-bottom: 16px;
    }

    &::placeholder {
      opacity: 0.45;
    }
  }

  // info
  &-info {
    margin-top: 16px;

    p {
      font-size: 12px;
      color: #999999;
    }
  }
}

// Cart note fixed
// -------------------------------------------------
@mixin cart-note-fixed() {
  .form-control {
    border: 1px solid #707070;
  }
}

// Cart total fixed
// -------------------------------------------------
@mixin cart-total-fixed() {
  position: relative;
  padding: 20px;
  background-color: #f8f8f8;

  &-title {
    margin-bottom: 12px;

    h5 {
      font-size: 20px;
      font-weight: 600;
      color: $global-text-color;
      text-transform: uppercase;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    span {
      font-size: 14px;
    }
  }

  &-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 6px;
    margin-top: 16px;
    background-color: #d9d9d9;

    span {
      color: $global-text-color;
      font-size: 24px;
      font-weight: 600;

      &.title {
        font-size: 18px;
      }
    }
  }

  .btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    .btn {
      height: 47px;
      text-align: center;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}

// Cart voucher fixed
// -------------------------------------------------
@mixin cart-voucher-fixed() {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 16px;
  padding: 20px;
  background-color: #f8f8f8;

  // title
  &-title {
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: $global-text-color;
      text-transform: uppercase;

      span {
        color: #313131;
        font-size: $global-font-size;
        font-weight: 400;
        @media (max-width: $screen-medium-max) {
          display: none;
        }
      }
    }
  }
  // form group
  .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: $screen-medium-max) {
      flex-direction: column;
    }
  }

  // form control
  .form-control {
    height: 47px;

    &::placeholder {
      font-size: $global-font-size;
      font-weight: 400;
    }

    @media (max-width: $screen-medium-max) {
      width: 100%;
    }

    @media (min-width: $screen-medium) {
      width: 50%;
      margin-right: 16px;
    }
  }

  // btn
  .btn {
    width: 50%;
    text-align: center;

    @media (max-width: $screen-medium-max) {
      display: none;
    }
  }
}

// Cart control
// -------------------------------------------------
.cart {
  @include cart-fixed;
  // title
  &-title {
    @include cart-title-fixed;
  }

  // header
  &-header {
    @include cart-header-fixed;
  }

  // body
  &-body {
    @include cart-body-fixed;
  }

  // items
  &-items {
    @include cart-items-fixed;
  }

  // article
  .article {
    @include cart-article-fixed;
  }

  // note
  &-note {
    @include cart-note-fixed;
  }

  // note
  &-total {
    @include cart-total-fixed;
  }

  // voucher
  &-voucher {
    @include cart-voucher-fixed;
  }
}
.radio-button-label {
  display: inline;
  padding-left: 10px;
}
.invoice-address-block {
  padding-left: 22.8px;
}
.radio-margin {
  margin-bottom: 15px;
}
