// =================================================
// BASE LESS
// =================================================
// Body & Html
// -------------------------------------------------
body {
  background-color: $white;
  font-size: $global-font-size;
  font-family: $global-font-family;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

// Link items
// -------------------------------------------------
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a,
link {
  font-family: $global-font-family;
  color: $global-text-color;
  font-size: $global-font-size;
  transition: all .2s ease;

  &:hover {
    color: $global-text-color;
    text-decoration: none;
    transition: all .2s ease-out;
  }
}

.link {
  // underline
  &-underline {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    color: $global-text-color;
    font-weight: 600;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: -5px;
      background-color: $dark;
      transition: width .3s ease;
    }

    &:hover {
      &:before {
        width: 80%;
      }
    }
  }
  &-disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}

// Text items
// -------------------------------------------------
p {
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: $global-text-color;
  font-family: $global-font-family;
}