// =================================================
// BACKGROUND LESS
// =================================================
// Background control
// -------------------------------------------------
.bg {
  &-faded {
    background-color: $faded !important;
  }

  &-muted {
    background-color: $muted !important;
  }
}