.order-history {
  .order-history-btn-set {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;

    @media (max-width: $screen-medium) {
      flex-direction: column;
    }

    .btn-muted {
      font-size: 14px;
      height: 35px;
      text-align: center;
      background-color: #CBCBCB;
      border-color: #CBCBCB;
      color: #ffffff;
      padding: 4px 20px;
      white-space: nowrap;

      @media (max-width: $screen-medium) {
       margin-bottom: 5px;
      }
    }
  }

  .order-history-items {
    .article-quantity, .article-total, .more-details {
      @media (max-width: $screen-medium) {
        margin-top: 0.5rem;
      }
    }
  }
}

.pagination-container {
  li.page-item {
    a {
      color: $dark;
      font-family: $global-font-family;
      border: none;
      border-radius: 0;

      &:hover {
        background: transparent;
        text-decoration: underline;
        font-weight: bold;
      }

    }
    &:first-child a {
      text-decoration: none;
    }

    &:last-child a {
      text-decoration: none;
    }
  }
}