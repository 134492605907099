// =================================================
// PAGE-NAV LESS
// =================================================
// Page-nav control
// -------------------------------------------------
.page-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 42px 0;
  width: 100%;
  background-color: transparent;

  // items
  &-items {
    position: relative;
    list-style: none;

    &:not(:last-child) {
      margin-right: 12px;
      padding-right: 12px;

      &:before {
        content: '›';
        position: absolute;
        right: 0;
        color: #999999;
      }
    }

    &.active {
      .page-nav-item {
        color: $global-text-color;
        cursor: default;
      }
    }
  }

  // item
  &-item {
    font-size: 14px;
    color: #999999;
    text-transform: capitalize;
  }
}