// =================================================
// REVIEW LESS
// =================================================
// Review fixed
// -------------------------------------------------
@mixin review-fixed() {

  // page
  .page-title {
    @media (min-width: $screen-medium) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      h5 {
        margin-right: 8px;
        margin-bottom: 0;
      }

      a {
        font-size: 14px;
        color: #000;
        font-weight: lighter;
        opacity: .45;
      }
    }
  }
}

// Cart fixed
// -------------------------------------------------
@mixin review-cart-fixed() {
  position: relative;
}

// Cart title fixed
// -------------------------------------------------
@mixin review-cart-title-fixed() {
  position: relative;

  @media (max-width: $screen-medium-max) {
    margin-top: 22px;
  }

  h5 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 18px;
  }
  span {
    position: relative;
    font-size: 14px;
    color: $global-text-color;

    &:before {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: -5px;
      background-color: $dark;
    }
  }
}

// Cart header fixed
// -------------------------------------------------
@mixin review-cart-header-fixed() {
  position: relative;
  width: 100%;
  margin-top: 46px;
  border-bottom: 1px solid $dark;
}

// Cart body fixed
// -------------------------------------------------
@mixin review-cart-body-fixed() {
  position: relative;
  margin-top: 5px;
  border-bottom: 2px solid $dark;
}

// Cart body fixed
// -------------------------------------------------
@mixin review-cart-items-fixed() {
  position: relative;
  padding-bottom: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid $dark;
    margin-bottom: 5px;
  }
  &-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

// Cart article fixed
// -------------------------------------------------
@mixin review-cart-article-fixed() {
  // detail
  &-detail {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  // info
  &-info {
    line-height: 1.5;
    position: relative;

    p {
      padding-bottom: 12px;
    }

    .collapse {
      &:not(.show) {
        display: block;
        height: 56px;
        overflow: hidden;
      }
    }

    .collapsing {
      height: 56px;
    }

    a {
      position: relative;
      padding-left: 28px;

      &.collapsed {
        position: relative;

        &:before {
          font-family: "Font Awesome 5 Pro";
          color: #999999;
          font-weight: 900;
          content: "\f078";
        }
      }

      &:not(.collapsed) {
        &:before {
          font-family: "Font Awesome 5 Pro";
          color: #999999;
          font-weight: 900;
          content: "\f077";
        }
      }
    }
  }
  // name
  &-name {
    @media (min-width: $screen-medium ) {
      max-width: 95%;
    }

    span {
      font-size: 18px;
      font-weight: 600;
    }
  }

  // btn-set
  &-btn-set {
    margin-top: 12px;

    .btn-muted {
      font-size: 14px;
      height: 35px;
      width: 100px;
      text-align: center;
      background-color: #CBCBCB;
      border-color: #CBCBCB;
      color: $white;
      padding: 4px 26px;

      &:hover {
        background-color: darken(#CBCBCB, 5%);
      }

      &:not(:last-child) {
        margin-right: 28px;
      }
    }
  }

  // quantity & total
  &-quantity,
  &-total {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    color: $dark;
  }
  &-total {
    font-weight: 600;
  }

  //reference
  &-reference {
    color: $dark;
    background-color: #F5F5F5;
    border: 1px solid #707070;

    @media (max-width: $screen-medium-max) {
      margin-bottom: 16px;
    }

    &::placeholder {
      opacity: 0.45;
    }
  }

  // info
  &-info {
    margin-top: 16px;

    p {
      font-size: 12px;
      color: #999999;
    }
  }
}


// Review Address
// -------------------------------------------------
@mixin review-address-fixed() {
  position: relative;
  padding: 26px;
  background-color: #F8F8F8;

  @media (min-width: $screen-medium) {
    margin-top: 66px;
  }
}

// Review payment
// -------------------------------------------------
@mixin review-payment-fixed() {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  padding: 26px;
  background-color: #F8F8F8;
  .title {
    flex: 1 0 96px;
  }
  .content {
    flex: 1 0 calc(100% - 96px);
  }
}

// Review accordion
// -------------------------------------------------
@mixin review-accordion-fixed() {
  position: relative;
  margin-top: 16px;
  width: 100%;

  .card {
    padding: 0;
    border: 0 none;
    // header
    &-header {
      background-color: transparent;
      border: 0 none;
      padding: 0;
      border-radius: 0;

      .btn {
        color: #000;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        width: 100%;
        padding: 12px 0;
        position: relative;

        &:before {
          font-family: "Font Awesome 5 Pro";
          font-weight: 900;
          content: "\f077";
          position: absolute;
          right: 0;
        }

        &.collapsed {
          &:before {
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            content: "\f078";
            position: absolute;
            right: 0;
          }
        }
      }
    }

    // body
    &-body {
      padding: 0;
      width: 100%;
      .review-address {
        width: 100%;
      }
    }
  }
}

// Review control
// -------------------------------------------------
.review {
  @include review-fixed;

  // cart
  &-cart {
    @include review-cart-fixed;

    &-title {
      @include review-cart-title-fixed;
    }

    &-header {
      @include review-cart-header-fixed;
    }

    &-body {
      @include review-cart-body-fixed;
    }

    &-items {
      @include review-cart-items-fixed;
    }

    // article
    .article {
      @include review-cart-article-fixed;
    }
  }

  // address
  &-address {
    @include review-address-fixed;
  }

  // payment
  &-payment {
    @include review-payment-fixed;
  }

  // accordion
  &-accordion {
    @include review-accordion-fixed;
  }
}

