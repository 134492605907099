// =================================================
// MAIN LESS
// =================================================

// Vendors
// -------------------------------------------------
@import "~bootstrap/scss/bootstrap.scss";
@import "vendor/custom-fonts/custom-fonts.css";
@import "vendor/fontawesome-v5-pro/css/all.min.css";

// Components
// -------------------------------------------------
@import "components/variables";

@import "components/address";
@import "components/background";
@import "components/base";
@import "components/button";
@import "components/cart";
@import "components/checkbox";
@import "components/carousel";
@import "components/collapse";
@import "components/container";
@import "components/finish";
@import "components/footer";
@import "components/form-control";
@import "components/header";
@import "components/login";
@import "components/page";
@import "components/page-nav";
@import "components/margin-padding";
@import "components/text-box";
@import "components/uploader";
@import "components/product-details";
@import "components/review";
@import "components/methods";
@import "components/breadcrumb";
@import "components/commodity-group";
@import "components/order-history";
@import "components/attribute-selector";

.header-nav {
  li.main-menu {
    position: relative;

    ul.sub-menu {
      position: absolute;
      display: none;
      width: auto;
      white-space: nowrap;

      & > li {
        background-color: $white;
        // height: 40px;
        // line-height: 40px;
        padding-right: 10px;

        &:hover {
          background-color: #bfbfbf;
          > ul.sub-sub-menu {
            display: flex;
          }
        }

        &:not(.product-menu):before {
          font-family: "Font Awesome 5 Pro";
          content: "\f0da";
          font-weight: 900;
          float: right;
          display: inline-block;
        }

        & > a {
          font-size: 20px;
          font-family: $global-font-family;
          display: block;
          color: $dark;
          padding: 0 40px;
          // max-width: 250px;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }

      ul.sub-sub-menu {
        display: none;
        position: absolute;
        top: 0;
        left: 100%;
        height: 100%;
        max-height: 320px;
        background-color: $faded;
        flex-direction: column;
        flex-wrap: wrap;

        & > li {
          background-color: $white;
          // height: 40px;
          // line-height: 40px;
          padding-right: 10px;

          &:hover {
            background-color: #bfbfbf;
            > ul.sub-sub-menu {
              display: flex;
            }
          }

          &:not(.product-menu):before {
            font-family: "Font Awesome 5 Pro";
            content: "\f0da";
            font-weight: 900;
            float: right;
            display: inline-block;
          }

          & > a {
            font-size: 20px;
            font-family: $global-font-family;
            display: block;
            color: $dark;
            padding: 0 40px;
            // max-width: 320px;
            // overflow: hidden;
            // text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .drop-down-menu:hover {
    ul.sub-menu {
      display: block;
    }
  }
}

ul.mobile-menu {
  position: relative;
  width: 100%;
  display: inline-table;
  text-transform: uppercase;
  text-align: left;
  font-family: $global-font-family;

  li {
    a {
      color: $dark !important;
      text-decoration: none;
      font-weight: 600;
      padding: 15px 35px;
      display: block;
      font-size: 20px;
      line-height: 30px;

      &:not(:only-child):after {
        font-family: "Font Awesome 5 Pro";
        content: "\f0da";
        font-weight: 900;
        float: right;
        display: inline-block;
        margin-right: -20px;
        font-size: 14px;
      }
    }

    a.mobile-back {
      text-transform: capitalize;
      font-size: 14px;
      padding-left: 0px;
      line-height: 20px;

      &:not(:only-child):after {
        font-family: "Font Awesome 5 Pro";
        content: "\f104";
        font-weight: normal;
        float: left;
        display: inline-block;
        margin-right: 20px;
        font-size: 30px;
        line-height: 20px;
      }
    }
  }

  ul {
    display: none;
  }

  .mobile-submenu {
    position: relative;
    left: 1px;
  }

  .mobile-subview {
    display: block;
    width: 100%;
  }

  .mobile-subover-visible {
    display: block;
  }

  .mobile-subover-hidden {
    display: none;
  }
}
