.use-mcp-style-attr-selector {
  .attribute-selector {
    .btn-link,
    label {
      color: #000000 !important;
    }

    // when button is in default mode
    .btn.btn-default {
      color: #000000 !important;
      border: 1px solid #000000 !important;
      &:hover {
        background: $primary;
        color: $text-primary !important;
      }
    }

    // when button is disabled
    .btn.btn-default.disabled {
      &:hover {
        color: #000000 !important;
      }
      color: #000000 !important;
    }

    // When button is active
    .btn.btn-default.active {
      &:hover {
        background: $primary;
        color: $text-primary !important;
      }
      background: $primary;
      color: $text-primary !important;
    }

    .input-list {
      > div {
        background-color: #f8f8f8;
        padding: 20px;
      }
    }
    .form-group {
      padding: 0px;
    }

    // when text field are focused
    .input-group > .form-control:focus {
      border: 1px solid $primary !important;
    }

    .react-select-wrapper.form-group {
      padding: 20px;
      label {
        position: initial;
        margin-bottom: 0px;
      }
    }
    .react-select-wrapper.form-group.form-group-active {
      label {
        font-size: 1.125rem;
      }

      // Attr Selecter Dropdown color handling
      // div[class$="MenuList"],
      // .css-1otvalh,
      // .css-11unzgr {
      // div {
      //   color: black !important;
      // }

      // // value not selected - dropdown selected
      // .css-1ltxh33 {
      //   color: green !important;
      // }
      // // value not selected - dropdown not hovered/selected
      // .css-9zho73 {
      //   color: orange !important;
      // }
      // // value selected - dropdown selected/not hovered
      // .css-32hxhb {
      //   color: pink !important;
      // }
      // // value selected - dropdown selected/hovered
      // .css-7wvw4 {
      //   color: red !important;
      // }
      // // value selected - dropdown disabled
      // .css-h70ltw {
      //   color: blue !important;
      // }
      // }
    }
  }
}
