@font-face {
  font-family: 'Roboto-Regular';
  src: url("roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url("roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: 'Roboto-Light';
  src: url("roboto/Roboto-Light.ttf");
}